@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}





body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: darkPrimaryscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.custom-shape-divider-bottom-1715358037 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1715358037 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1715358037 .shape-fill {
  fill: #2E2B2F;
}

.custom-shape-divider-top-1715358107 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1715358107 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1715358107 .shape-fill {
  fill: #2E2B2F;
}




.main-div:has(.main-image:hover){
  .main-text {
    display: block;
  }
}

.main-div:has(.main-text:hover){
  .main-image {
    transform: scale(1.1);
    filter: grayscale(100%);
    opacity: 50%;
  }
  .main-text {
    display: block;
  }
}



  

/**
* Animations 
*/


.animation {
  position: relative; /* Permet de positionner l'élément par rapport à son parent */
  visibility: hidden; /* Cache l'élément par défaut */
}


/* Animation right-to-left */
.right-to-left {
  animation-name: right-to-left-animation; /* Nom de l'animation */
  animation-duration: 1s; /* Durée de l'animation */
  animation-fill-mode: forwards; /* Maintient l'état final de l'animation */
}

@keyframes right-to-left-animation {
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
}

/* Animation left-to-right */
.left-to-right {
  animation-name: left-to-right-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes left-to-right-animation {
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
}

/* Animation top-to-bot */
.top-to-bot {
  animation-name: top-to-bot-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes top-to-bot-animation {
  from {
    top: 0%;
  }
  to {
    top: 50%;
  }
}

/* Animation bot-to-top */
.bot-to-top {
  animation-name: bot-to-top-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes bot-to-top-animation {
  from {
    bottom: 0%;
  }
  to {
    bottom: 100%;
  }
}



/* Barre de défilement */
::-webkit-scrollbar {
  width: 3px; 
  background-color: black;
  opacity: 0%;
}

/* Poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background-color: #5386E4; /* Couleur de fond de la poignée */
  border-radius: 6px; /* Rayon de la bordure de la poignée */
}



/**
* Image accueil
*/


.card{
  width: 300px;
  aspect-ratio: 1/1.3;
  position: relative;
}

.card .image-box{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card .image-box img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(0), brightness(1);
  scale: 1.2;
  transition:
    scale 2s ease-in-out,
    filter 1s ease-in-out;
}

.card:hover .image-box img{
  filter: grayscale(1), brightness(0.7);
  scale: 1;
}

.card .text-box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.text-box :is(.main, .sub){
  height: 34px;
  line-height: 34px;
  overflow: hidden;
}

.text-box .main{
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.text-box .sub{
  font-size: 14px;
  font-weight: 300;
}

.main span, .sub span{
  display: block;
  transition: transform 0.5s ease;
}

.main span{
  transition-delay: 0.2s;
  transform: translateY(100%);
}


.sub span{
  transform: translateY(-100%);
  transition-delay: 0.5s;
}

.experience ul {
  list-style-type: disc; /* Assure-toi que les puces sont des disques */
  margin-left: 20px; /* Ajoute une marge à gauche pour bien décaler la liste */
}

.experience li {
  margin-bottom: 0.75rem; /* Ajoute un espace entre les éléments de la liste */
}


.delay-border-radius {
  transition-property: border-radius;
  transition-duration: 0ms;
  transition-delay: 1000ms;
}
